import { CATEGORY_RANKING, WEIGHTED_FAVOURITES } from 'constants/productsSorting';

export const favouritesSortOptions = [
  {
    children: 'Category',
    value: CATEGORY_RANKING,
  },
  {
    children: 'Purchase frequency',
    value: WEIGHTED_FAVOURITES,
  },
];
